import UserContext from 'Contexts/User';

import coinParliament, { functions } from '../../../firebaseCoinParliament';
import React, { useContext, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PAX from '../../../assets/logos/PAX.png';
import VTE from '../../../assets/logos/VTE.png';
import { httpsCallable } from 'firebase/functions';
import axios from 'axios';



const Logo = (symbol:any) => {
  return (
    <>
    
      {symbol  && <Image
        src={process.env.PUBLIC_URL + `/images/logos/${symbol?.symbol?.toUpperCase()}.svg`}
      style={{
        // margin: "0 auto",
        width: `${symbol !== "ETH" ? "40px" : "35px"}`,
        height: `${symbol !== "ETH" ? "40px" : "40px"}`,
      }}
      onError={(e) =>
        ((e.target as HTMLImageElement).src = "/images/no_logo.png")
      }
      />}
    </>
  );
};

function ReceivedBalance({ ActiveProject }: {ActiveProject:any}) {

  const [pendingAmount, setPendingAmount] = useState({
    ETH: 0,
    BNB: 0,
    MATIC: 0,
    USDT: 0,
    VTE: 0,
  })
  const userIds = JSON.parse((localStorage.getItem('userId') || "{}"));
  let navigate = useNavigate(); 
  const { userInfo } = useContext(UserContext);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setModalShow(false);
  const [completedPAX, setCompletePax] = useState(0);
  const [VETValue, setVETValue] = useState(0);
  const getCompletedPaxCountOfUserData = httpsCallable(functions, "getCompletedPaxCountOfUser");
  const getCompletedPaxCountOfUser = async () => {
    if (userInfo?.uid) {
      const data = {
        userId: userIds?.coin
      }
      axios.post(`${process.env.REACT_APP_COIN_API}/getCompletedPaxCountOfUser`, { data }).then((res) => {        
        if (Object.keys(res?.data?.result?.result || {}).length) setCompletePax(res?.data?.result?.result || 0);
      }).catch((err) => {
        
      });
    }
  };

  const getVteValue = async() => {
    const referUser = await coinParliament.firestore().collection('users').where('email', '==', userInfo?.email).get();
    if (!referUser.empty) {
      referUser.forEach((doc: any) => {        
        console.log(doc.data(), "doc.data()")

        setVETValue(doc.data()?.rewardStatistics?.rewardedVTE || 0)
      });
    }
  }
  

  useEffect(() => {
    if (userInfo?.uid && (ActiveProject == "Coin Parliament" || ActiveProject == "All Parliament") ) {      
      getCompletedPaxCountOfUser()
      getVteValue()
    } else {
      setVETValue(0)
      setCompletePax(0)
    }
    
  }, [userInfo?.uid, ActiveProject]);

  return (
    <div>
      <>
        
          <div className='d-flex justify-content-center align-items-center'
            style={{
              // color: "white"
            }}
          >
            <div className='mt-3'>
              <p className='mx-3' style={{
                fontSize: "16px"
              }}>{"YOUR REWARD RECEIVE AMOUNT".toLocaleUpperCase()}</p>
              <div className='d-flex justify-content-around align-items-center my-2'
                style={{
                  background: "white",
                  borderRadius: "5px",
                  padding: "8px 8px",
                }}
              >
                <div className='d-flex align-items-center'>
                  <img src={PAX} alt=""
                    style={{
                      // margin: "0 auto",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <p className=''
                    style={{
                      marginLeft: `${"23px"}`,
                      color: "black",
                    }}
                  >PAX</p>
                </div>
                <p className=''
                  style={{
                    color: "#6352E8",
                  }}
                >{completedPAX}</p>
            </div>
            <div className='d-flex justify-content-around align-items-center my-2'
              style={{
                background: "white",
                borderRadius: "5px",
                padding: "8px 8px",
              }}
            >
              <div className='d-flex align-items-center'>
                <img src={VTE} alt=""
                  style={{
                    // margin: "0 auto",
                    width: "40px",
                    height: "40px",
                  }}
                />
                <p className=''
                  style={{
                    marginLeft: `${"23px"}`,
                    color: "black",
                  }}
                >VTE</p>
              </div>
              <p className=''
                style={{
                  color: "#6352E8",
                }}
              >{VETValue}</p>
            </div>
              <p className='mx-3' style={{
                fontSize: "18px",
                margin: "20px 0px 10px 0px"
              }}>{"Your Referral Receive Amount".toLocaleUpperCase()}</p>

              {Object?.keys(pendingAmount)?.length ?
                <div className='d-flex flex-column'>
                  {Object?.keys(pendingAmount)?.map((value, index) => {
                    if (value == "ETH" || value == "BNB" || value == "MATIC" || value == "USDT") {
                      console.log(value,"valuevaluevalue")
                      return <>

                        <div className='d-flex justify-content-around align-items-center my-2 '
                          style={{
                            background: "white",
                            borderRadius: "5px",
                            padding: "8px",
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <Logo
                              symbol={value}

                            />
                            <p className=''
                              style={{
                                marginLeft: `${value == "ETH" ? "23px" : "20px"}`,
                                color: "black",
                              }}
                            >{value == "MATIC" ? "POL" : value} </p>
                          </div>

                          {/* @ts-ignore */}
                          <p className=''
                            style={{
                              color: "#6352E8",
                            }}
                          >{pendingAmount[value] != 0 ? Number(pendingAmount[value])?.toFixed(4) : 0}</p>
                        </div>
                      </>
                    }

                  })
                }
                                
                </div>
                :
                <div className="d-flex justify-content-center" >
                  <p className='mt-3'>Pending Payment Amount is not found</p>
                </div>
              }
            </div>            
          </div>
      </>
    </div>
  )
}

export default ReceivedBalance