import React, { useContext, useEffect, useState } from 'react'
import PAX from '../../../assets/logos/PAX.png';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserContext from 'Contexts/User';
import axios from 'axios';
import { showToast } from 'App';
import { ToastType } from 'Contexts/Notification';


const Logo = (symbol:any) => {
  return (
    <Image
      src={process.env.PUBLIC_URL + `/images/logos/${symbol?.symbol?.toUpperCase()}.svg`}
      style={{
        // margin: "0 auto",
        width: `${symbol !== "ETH" ? "40px" : "35px"}`,
        height: `${symbol !== "ETH" ? "40px" : "40px"}`,
      }}
      onError={(e) =>
        ((e.target as HTMLImageElement).src = "/images/no_logo.png")
      }
    />
  );
};
function PendingBalance({ ActiveProject }: { ActiveProject: any }) {
  const [pendingAmount, setPendingAmount] = useState({
    ETH: 0,
    BNB: 0,
    MATIC: 0,
    USDT: 0,
  })

  let navigate = useNavigate();
  const { userInfo, user } = useContext(UserContext);
  const [pendingPax, setPendingPax] = React.useState<any>();  
  const [allValuesGreaterThanOne, setAllValuesGreaterThanOne] = useState<any>(false);
  
  const userIds = JSON.parse((localStorage.getItem('userId') || "{}"));  

  const sortDataByTimestamp = (data: any) => {
    return data.sort((a: any, b: any) => {
      if (a?.timestamp?._seconds !== b?.timestamp?._seconds) {
        return a?.timestamp?._seconds - b?.timestamp?._seconds;
      }
      return a?.timestamp?._nanoseconds - b?.timestamp?._nanoseconds;
    });
  };

  const ShowPendingAmount = (userId: any) => {
    console.log("ShowPendingAmount")
    axios.get(`${process.env.REACT_APP_COIN_API}/api/v1//payment/getPendingPaymentbyUserId/${userId}`)
      .then(async (response) => {
        const checkValues = (obj: any) => {
          for (let key in obj) {
            if (obj[key] <= 1) {
              return false;
            }
          }
          return true;
        };

        // Update the state with the result
        setAllValuesGreaterThanOne(checkValues(response?.data?.data));
        setPendingAmount(response.data.data)
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }
  const ShowPendingPax = () => {
    console.log("ShowPendingPaxShowPendingPaxShowPendingPax")
    axios.post(`${process.env.REACT_APP_COIN_API}/api/v1/payment/getAllPendingPaxByUserId`, {
      userId: userIds?.coin
    }).then((res: any) => {
      console.log(res?.data?.data?.result, "resdata");
      //res?.data?.data?.result?.paxList[0].currentPaxValue
      if (res?.data && res?.data?.data?.result?.pendingPaxTotal > 0) {

        const sortedData = sortDataByTimestamp(res?.data?.data?.result?.paxList);
        const timestamp = sortedData[0];
        const date = new Date(timestamp.timestamp._seconds * 1000);
        //    const endTime = date.getTime() + (24 * 60 * 60 * 1000);
        const endTime = date.getTime() + (5 * 61 * 1000);
        setPendingPax({
          ...timestamp,
          formattedDate: date.toLocaleString(),
          endTime: endTime,
          pendingPaxValue: res?.data?.data?.result?.pendingPaxTotal
        });
      } else {
        setPendingPax({
          pendingPaxValue: 0,
        })
      }
    }).catch((err: any) => {
      console.log(err, "resultdata")
    })
  }

  useEffect(() => {    
    if (user?.uid && (ActiveProject == "Coin Parliament" || ActiveProject == "All Parliament")) {      
      if (userIds.coin) {        
        ShowPendingAmount(userIds.coin)
      }
      ShowPendingPax();
    }
  }, [userInfo?.uid, ActiveProject])
    

  
  return (
    <div className='d-flex justify-content-center align-items-center'
    >
      <div className='mt-3'>
        {userInfo?.uid && <>
          <p className='mx-3 ' style={{
            fontSize: "16px"
          }}>{"YOUR REWARD PENDING  AMOUNT".toLocaleUpperCase()}</p>

          <div className='d-flex justify-content-around align-items-center my-2'
            style={{
              background: "white",
              borderRadius: "5px",
              padding: "8px 8px",
            }}
          >
            <div className='d-flex align-items-center'>
              <img src={PAX} alt=""
                style={{
                  // margin: "0 auto",
                  width: "40px",
                  height: "40px",
                }}
              />
              <p className=''
                style={{
                  marginLeft: `${"23px"}`
                }}
              >PAX</p>
            </div>
            <p className=''
              style={{
                color: "#6352E8",
              }}

            >
              {pendingPax?.pendingPaxValue || 0}
            </p>

          </div>          
        </>}        
        <p className='mx-3 mb-0' style={{
          fontSize: "16px",
          margin: "20px 0px 10px 0px"
        }}>{"Your Referral Pending Amount".toLocaleUpperCase()}</p>
        {Object.keys(pendingAmount).length ?
          <div className='d-flex flex-column'>
            {Object.keys(pendingAmount)?.map((value, index) => {
              if (value == "ETH" || value == "BNB" || value == "MATIC" || value == "USDT") {
                return <>

                  <div className='d-flex justify-content-around align-items-center my-2 '
                    style={{
                      background: "white",
                      borderRadius: "5px",
                      padding: "8px",
                    }}
                  >
                    <div className='d-flex align-items-center'>
                      <Logo
                        symbol={value}

                      />
                      <p className=''
                        style={{
                          marginLeft: `${value == "ETH" ? "23px" : "20px"}`
                        }}
                      >{value == "MATIC" ? "POL" : value} </p>
                    </div>

                    {/* @ts-ignore */}
                    <p className=''
                      style={{
                        color: "#6352E8",
                      }}
                    >{pendingAmount[value] != 0 ? Number(pendingAmount[value]).toFixed(4) : 0}</p>
                  </div>
                </>
              }

            })
            }
          </div>
          :
          <div className="d-flex justify-content-center" >
            <p className='mt-3'>Pending Payment Amount is not found</p>
          </div>
        }        
      </div>      
    </div>
  )
}

export default PendingBalance